import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import doctorManagement from "../../img/Projects/doctor-management.png";
import bitsOfCode from "../../img/Projects/blog.png";
import cryptoExchange from  "../../img/Projects/crypto-exchange.png";
import celoExchange from  "../../img/Projects/celo-exchange.png";
import cloudStorage from "../../img/Projects/cloud-storage.png";
import ecommerce from  "../../img/Projects/ecommerce.png";
function Projects() {
  return (
    <Container fluid className="project-section">
      <div className="lead-title text-white">
      <h1 className="title-font title-font-size">
          Recent Works 
        </h1>
        </div>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={cryptoExchange}
              isBlog={false}
              title="Decentralized Crypto Exchange"
              description="
              A token exchange in which users can deposit or withdraw ether from the exchange to place a buy or sell order for GC Token (which is my own cryptocurrency).
              
              Trades and orders are updated in real-time and MetaMask is used to provide HD Wallet.
              
              Solidity is used for smart contracts, web3.js is used to connect with the ethereum blockchain, and React for the front-end.
              
              "
              link="https://crypto-exchange-b7aee.web.app/"
            />
          </Col>

          {/* <Col md={5} className="project-card">
            <ProjectCard
              imgPath={ecommerce}
              isBlog={false}
              title="E-commerce Mobile App"
              description="An ecommerce mobile and also web application in flutter with Cashfree payment gateway, user mobile authentication and also an admin app to manage products and receive orders. For backend, firebase is used. "
              link="https://apps.apple.com/in/app/bindaas-mart/id1584779511"
            />
          </Col> */}

          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={doctorManagement}
              isBlog={false}
              title="Doctor Management App"
              description="A doctor can register, access his patients, manage appointments, change working hours and availability and add the services provided. There is also a user application for this to book doctors."
              link="https://apps.apple.com/cg/app/7sur7-rdv-pro/id1560290006#?platform=iphone"
            />
          </Col>

          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={cloudStorage}
              isBlog={false}
              title="Decentralized Cloud Storage"
              description="A decentralized cloud storage, in which user can store a file on the ethereum blockchain by the use of IPFS. file can only accessed by the user who stored it.

              Smart contracts in solidity is deployed on rinkeby network. Torus is used to provide user authentication and wallet.
              "
              link="https://decentralized-cloud-storage.web.app/"
            />
          </Col>

          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={celoExchange}
              isBlog={false}
              title="Crypto Bank"
              description="A web3 account is provided for every user that is further encrypted by password. 
              Users can deposit funds and also transfer them to another account.

              App works on Aurora, Fantom and Rinkeby networks.
              "
              link="https://conceal-protocol.web.app/"
            />
          </Col>

        </Row>
      </Container>
  );
}

export default Projects;
