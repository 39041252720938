import React from 'react';
import {
    AiFillGithub,
    AiOutlineMail,
    AiFillInstagram,
  } from "react-icons/ai";
  import { FaLinkedinIn } from "react-icons/fa";
  
const Contact = () => {
    return(
        <>
            <div className="contact section-title mt-5">
                <div className="container text-white">
                    <div className="row align-items-center">
                        <div className="col-md-7 mx-auto">
                            <div className="contact-title mb-5 mt-5">
                                <h1 className="title-font title-font-size">
                                Contact
                                </h1>
                                <p className="mt-4 mb-4 title-font-2">
                                  
                                  Drop me a Hi and I will revert back to you.
                                </p>
                            </div>
                        </div>
                        <div>

                        </div>
                </div>
            </div>
          <div md={12} className="home-about-social">
      
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Gautamarora207"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@gautamarora.in"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/gautamarora207/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/_gautam.arora_/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
            <br></br>       
        </>
    );
}

export default Contact;