import React from 'react';
import '../App.scss';
import Projects from './Projects/Projects';
import LetsWork from '../components/LetsWorkTogether';
import Skills from './Skills';

import flutter from  "../img/skills/flutter.svg";
import js from  "../img/skills/javascript.png";
import react from  "../img/skills/react.png";
import ethereum from  "../img/skills/ethereum.png";
import python from  "../img/skills/python.png";
import node from  "../img/skills/node.png";

const Body = () => {

    return(
        <>
            <section className="about-us-area text-white">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="about-us-text-area mb-5 mt-5">
                                <h2 className="about-us-text title-font-3">
                                    I specialize in Flutter Development,
                                    <br/>
                                    Enthusiastic about Blockchain.
                                </h2>
                          
                                <Skills
                                    image={[
                                        flutter,
                                        react,
                                        python,
                                        ethereum,
                                        js,
                                        node
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Projects/>
            {/* <Services /> */}
            <LetsWork/>
        </>
    );
}

export default Body;