import React from 'react';
import Projects from '../components/Projects/Projects';
const Works = () => {
    return(
        <>
        <Projects/>
        </>
    );
}

export default Works;