import React, { Component } from 'react';
import '../App.scss';

class Banner extends Component {
  initThree() {
    const canvas = document.querySelector('canvas');
    const c = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const mouse = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    // Event Listeners
    window.addEventListener('mousemove', (event) => {
      mouse.x = event.clientX;
      mouse.y = event.clientY;
    });

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      this.setState();
      init();
    });

    window.setInterval(init, 10000);

    // Objects
    class Particle {
      constructor(x, y, radius, color, velocity) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.color = color;
        this.velocity = velocity;
        this.ttl = 1000;
      }

      draw() {
        c.beginPath();
        c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        c.fillStyle = this.color;
        c.fill();
        c.closePath();
      }

      update() {
        this.draw();
        this.x += this.velocity.x;
        this.y += this.velocity.y;
        this.ttl--;
      }
    }

    // Implementation
    let particles;
    function init() {
      particles = [];
    }

    let hue = 0;
    let hueRadians = 0;
    function generateRing() {
      setTimeout(generateRing, 250);
      hue = Math.sin(hueRadians);

      const particleCount = 80;

      for (let i = 0; i < particleCount; i++) {
        // full circle = pi * 225 radians
        const radian = (Math.PI * 2) / particleCount;
        const x = mouse.x;
        const y = mouse.y;
        particles.push(
          new Particle(x, y, 5, `hsl(${Math.abs(hue * 720)}, 80%, 25%)`, {
            x: Math.cos(radian * i) * 3,
            y: Math.sin(radian * i) * 3,
          })
        );
      }

      hueRadians += 0.01;
    }

    // Animate Object
    var animate = function () {
      requestAnimationFrame(animate);
      c.fillStyle = 'rgba(0, 0, 0, 0.1)';
      c.fillRect(0, 0, canvas.width, canvas.height);

      particles.forEach((particle, i) => {
        if (particle.ttl < 0) {
          particles.splice(i, 1);
        } else {
          particle.update();
        }
      });
    };

    init();
    animate();
    generateRing();
  }

  componentDidMount() {
    this.initThree();
  }

  render() {
    var onclick = function () {
      window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
    };
    return (
      <div className="wrapper">
        <h1 className="canvasText">
          Hey! <br></br>I'm
          <span className="text-highlight"> Gautam Arora </span>
          <br></br>
          Flutter & Blockchain Developer
          {window.innerWidth > 800 && window.innerHeight > 500 ? (
            <div id="scroll" className="canvasText scroll">
              <section id="scrollButton">
                <button id="button" onClick={onclick}>
                  <span></span>Scroll
                </button>
              </section>
            </div>
          ) : (
            <div></div>
          )}
        </h1>

        <canvas></canvas>
      </div>
    );
  }
}

export default Banner;
