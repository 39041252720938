import '../App.scss';


const Skills = (props) => {
    return (
      <>
        <div className="skills">
          <div className="first">
            <div className="container-content">
              <div className="inner-skill-container">
                {props.image.map((e, index) => (
                  <div key={index}>
                    <img src={e} alt="img" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default Skills;