import React,  { Component } from 'react';
import {Link} from 'react-router-dom';
import './Navbar.scss';
import { connect } from 'react-redux';

class NavBar extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          dataToggle: 'collapse',
          width: 0, 
          height: 0 ,
        };
        this.updateDataToggle = this.updateDataToggle.bind(this);
    }

    updateDataToggle() {
        if(window.innerWidth > 990) {
            this.setState({ 
                dataToggle: ''
            });
        } else {
            this.setState({ 
                dataToggle: 'collapse'
            });
        }
    }

    componentDidMount() {
        this.updateDataToggle();
        window.addEventListener('resize', this.updateDataToggle);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataToggle);
    }
  

    render() {
        return(
            <header className="header-transparent">
                <nav className="navbar navbar-expand-lg text-center">
                    <div className="container">
                        <Link to="/" className="navbar-brand">Gautam Arora</Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">
                                <i className="fi-xwluxl-three-bars-wide"></i>
                            </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav navbar-nav ml-auto">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link" data-toggle={this.state.dataToggle} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/about" className="nav-link" data-toggle={this.state.dataToggle} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">About</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="/services" className="nav-link" data-toggle={this.state.dataToggle} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Services</Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link to="/works" className="nav-link" data-toggle={this.state.dataToggle} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Works</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link" data-toggle={this.state.dataToggle} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Contact</Link>
                                </li>
                            </ul>
                        </div> 
                    </div>
                </nav>
            </header>
        );
    }

}

const mapStateToProps = state => {
    return {
        items: state.works
    }
}

export default connect(mapStateToProps) (NavBar);